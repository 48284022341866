<template>
  <Popover trigger="hover" placement="top">
    <template #content>
      <div class="titleTips">
        <div class="weight">找不到对应{{ title }}？</div>
        <div class="line">
          <span class="tips">·</span
          ><span
            >该页面仅支持选择已有{{ title }}数据，不支持新增{{ title }}。</span
          >
        </div>
        <div class="line">
          <span class="tips">·</span
          ><span
            >如有新增需求，建议去
            <span
              class="decoration"
              @click="
                () => {
                  this.$router.push(path);
                }
              "
              >{{ title }}管理</span
            >
            先新增{{ title }}，再进行此操作。</span
          >
        </div>
      </div>
    </template>
    <span class="sortBox">
      <QuestionCircleOutlined style="color: #5a80b0;font-size:14px" class="question" />
    </span>
  </Popover>
</template>
<script>
import { Popover } from "ant-design-vue";
import { QuestionCircleOutlined } from "@ant-design/icons-vue";
export default {
  components: {
    QuestionCircleOutlined,
    Popover,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    path: {
      type: String,
      default: "",
    },
  },
};
</script>
<style scoped lang="scss">
.titleTips {
  .line {
    line-height: 20px;
  }
  .weight {
    font-size: 14px;
    font-weight: 900;
    line-height: 26px;
  }
  .tips {
    font-size: 14px;
    font-weight: 900;
    margin-right: 7px;
  }
  .decoration {
    text-decoration: underline;
    color: rgb(0, 139, 207);
    cursor: pointer;
  }
}
</style>