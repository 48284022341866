<template>
  <div class="relativeBox">
    <Select
      class="singleProductItemList singleProductItemListSelect"
      v-model:value="formState.packSpId"
      @click="showModal"
      :disabled="!todoEdit"
      :class="todoEdit ? '' : 'noBoder miniwidth'"
      :placeholder="!todoEdit ? '' : '请选择'"
    >
      <Option :value="formState.packSpId">
        {{ formState.packSpId ? formState.packSpName : "" }}
      </Option>
    </Select>
    <SearchOutlined class="SearchOutlined" v-if="todoEdit" />
  </div>
  <Modal
    v-model:visible="visible"
    centered
    @ok="handleOk"
    class="modal singleProductItemList"
    width="80%"
  >
    <template #title>
      <DecorateModalTitle modalTitle="选择单品" />
    </template>
    <div class="searchBox">
      <div></div>
      <div>
        <Input
          v-model:value="spNameOrSpCodeOrSpBarCode"
          placeholder="单品名称/单品编码/单品条形码"
        />
        <Button style="padding: 5px 30px" type="primary" @click="search"
          >搜索</Button
        >
      </div>
    </div>
    <div class="tableContainer">
      <div class="tableBox">
        <Table
          :style="{ minHeight: '400px' }"
          rowKey="id"
          :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
            type: 'radio',
          }"
          :columns="columns"
          :data-source="data"
          :pagination="false"
          :scroll="scroll"
          :loading="loading"
        >
          <template #spName="{ record }">
            <div>
              {{ record.spName }}
            </div>
            <div style="color: #ccc">{{ record.spCode }}</div>
          </template>
        </Table>
      </div>
      <div
        style="
          display: flex;
          justify-content: space-between;
          margin-top: 20px;
          align-items: center;
        "
      >
        <div>共 {{ total }} 条数据,当前为第{{ pageNum }}页数据</div>
        <Pagination
          show-size-changer
          show-quick-jumper
          v-model:current="pageCurrent"
          v-model:pageSize="pageSize"
          :total="total"
          @showSizeChange="onShowSizeChange"
          :page-size-options="['10', '20', '50', '100']"
        />
      </div>
    </div>
  </Modal>
</template>
<script>
import { defineComponent } from "vue";
import {
  Modal,
  Table,
  Pagination,
  Input,
  message,
  Select,
  Button,
} from "ant-design-vue";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons-vue";
import { listSingleProductForPackage } from "@/api/terminalList.js";
import { singleProductItemsColumns } from "@/api/fixedData.js";
import DecorateModalTitle from "@/components/modification/DecorateModalTitle.vue";
const { Option } = Select;
export default defineComponent({
  components: {
    Modal,
    Input,
    PlusOutlined,
    SearchOutlined,
    Option,
    Select,
    Table,
    Pagination,
    Button,
    DecorateModalTitle,
  },
  props: {
    formState: {
      type: Object,
      defaul: {},
    },
    todoEdit: {
      type: Boolean,
      defaul: false,
    },
  },
  data() {
    return {
      visible: false,
      selectedRowKeys: [],
      columns: [],
      //table的数据
      data: [],
      //table的布局
      scroll: { x: "100%", y: 520 },
      //table的loading
      loading: true,
      // 当前页数
      pageCurrent: 1,
      // 每页数据数量
      pageSize: 10,
      pageNum: 1,
      //总数据量
      total: 0,
      spNameOrSpCodeOrSpBarCode: "",
      packSpNamekeep: "",
    };
  },
  methods: {
    showModal() {
      if (!this.todoEdit) {
        return;
      }
      this.visible = true;
    },
    handleOk() {
      this.formState.packSpId = this.selectedRowKeys[0];
      this.formState.packSpName = this.packSpNamekeep;
      this.formState.stockUnitCodeKeep = this.stockUnitCode; //传选中项的单位到formState
      this.visible = false;
    },
    //复选结果
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
      this.packSpNamekeep = this.computedName();
      this.stockUnitCode = this.computedStockUnitCode();
    },
    //更换每页显示数量
    onShowSizeChange(current, pageSize) {
      this.pageNum = 1;
      this.pageCurrent = 1;
      this.pageSize = pageSize;
      this.getTerminal();
    },
    //搜索
    search() {
      this.searchFlag = true;
      this.pageNum = 1;
      this.getTerminal();
      this.pageCurrent = 1;
    },
    //获取单品列表
    getTerminal() {
      this.loading = true;
      let currentId;
      if (this.$route.params.id == "new") {
        currentId = "";
      } else {
        currentId = this.$route.params.id;
      }
      return listSingleProductForPackage({
        spNameOrSpCodeOrSpBarCode: this.spNameOrSpCodeOrSpBarCode,
        currentId: currentId,
        pageSize: this.pageSize,
        pageNum: this.pageNum,
      }).then((res) => {
        let result = res.data.data;
        this.total = result.totalRecord;
        this.data = result.data;
        this.loading = false;
        result.data.forEach((item) => {
          if (item.spName == this.formState.packSpName) {
            this.stockUnitCode = item.stockUnitCode;
            this.formState.stockUnitCodeKeep = this.stockUnitCode; //传选中项的单位到formState
          }
        });
      });
    },
    //计算当前选中项的名字
    computedName() {
      let result = "";
      this.data.forEach((item) => {
        if (item.id == this.selectedRowKeys[0]) {
          result = item.spName;
        }
      });
      return result;
    },
    computedStockUnitCode() {
      let result = "";
      this.data.forEach((item) => {
        if (item.id == this.selectedRowKeys[0]) {
          result = item.stockUnitCode;
        }
      });
      return result;
    },
  },
  watch: {
    //监听页码和页面数量变化
    pageCurrent() {
      //
      if (this.searchFlag === false) {
        this.spNameOrSpCodeOrSpBarCode = "";
      }
      this.pageNum = this.pageCurrent;
      this.getTerminal();
    },
  },
  mounted() {
    //
    this.getTerminal().then(() => {
      if (this.$route.params.id != "new") {
        let id = this.formState.packSpId;
        this.selectedRowKeys = [Number(id)];
      }
    });

    this.columns = singleProductItemsColumns;
  },
});
</script>
<style lang="scss" scoped>
.modal {
  border-radius: 20px;
}
.tableContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  .tableBox {
    align-self: center;
  }
}
.searchBox {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  margin-bottom: 20px;
  > div {
    display: flex;
    input {
      width: 200px;
      margin-right: 20px;
    }
  }
}
.singleProductItemListSelect {
  width: 400px;
}
@media screen and (max-width: 1610px) {
  .singleProductItemListSelect {
    width: 300px;
  }
}
</style>
<style lang="scss" >
.miniwidth {
  width: 100% !important;
}
.relativeBox {
  position: relative;
  .SearchOutlined {
    position: absolute;
    right: 12px;
    top: 15px;
    color: #bfbfbf;
    font-size: 14px;
  }
}

.singleProductItemList {
  .ant-modal-body {
    padding: 0 50px;
  }
  .noBoder {
    border: none !important;
    background: #fff !important;
    color: #595959 !important;
    position: relative;
    top: -4px;
    .ant-select-selector {
      border: none !important;
      background: #fff !important;
      color: #595959 !important;
      position: relative;
    }
    .ant-select-arrow {
      display: none;
    }
  }
}
.singleProductItemList .ant-select-selection-placeholder {
  line-height: 40px !important;
}
.singleProductItemList .anticon-down {
  display: none;
}
</style>