<template>
  <div>
    <Modal
      v-model:visible="visible"
      centered
      @ok="handleOk"
      class="modal CategroyModal"
      :confirmLoading="confirmLoading"
      width="800px"
      :okButtonProps="{ disabled: formState.categoryName == '' }"
    >
      <template #title>
        <DecorateModalTitle modalTitle="新增品类" />
      </template>
      <div class="outcell">
        <Form
          :model="formState"
          :rules="rules"
          ref="formRef"
          :label-col="{ style: { width: '80px' } }"
        >
          <Form-item label="品类名称" name="categoryName">
            <Input
              v-model:value="formState.categoryName"
              placeholder="请输入"
              style="width: 360px"
            />
          </Form-item>
          <div class="positionBox" >
            <Form-item label="上级品类">
              <CateforySelectReduce
                :formState="formState"
                style="width: 360px"
                ref="CateforySelectReduce"
              />
            </Form-item>
            <span style="color: #bfbfbf">
              不选择上级品类时,默认新建品类为一级品类
            </span>
          </div>
        </Form>
      </div>
    </Modal>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import { Modal, Form, Input, Cascader, Upload, message } from "ant-design-vue";
import { PlusOutlined } from "@ant-design/icons-vue";
import { addCategory, categoryList } from "@/api/terminalList.js";
import CateforySelectReduce from "@/components/singleProduct/CateforySelectReduce.vue";
import DecorateModalTitle from "@/components/modification/DecorateModalTitle.vue";
const { Item } = Form;
export default defineComponent({
  components: {
    Modal,
    Form,
    FormItem: Item,
    Input,
    Cascader,
    Upload,
    PlusOutlined,
    CateforySelectReduce,
    DecorateModalTitle,
  },
  props: {
    brandList: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      visible: false,
      formState: {
        categoryName:'',
        categoryNameList:[]
      },
      rules: {
        categoryName: [
          {
            required: true,
            message: "请输入品类名称",
            trigger: "blur",
          },
          { min: 0, max: 50, message: "最多50字符", trigger: "blur" },
        ],
      },
      confirmLoading: false,
    };
  },
  methods: {
    showModal() {
      this.visible = true;
      this.$refs.formRef.resetFields();
    },
    handleOk() {
      this.$refs.formRef.validate().then(() => {
        let categoryNameList = this.formState.categoryNameList;
        let categoryName = this.formState.categoryName;
        let categoryParCode;
        if (this.formState.categoryNameList) {
          categoryParCode = categoryNameList[categoryNameList.length - 1];
        } else {
          categoryParCode = "";
        }

        let sort;
        this.confirmLoading = true;
        categoryList(categoryParCode).then((res) => {
          if (res.data.success) {
            sort = 1000 - res.data.data.length;
          }
          // else {
          //   message.error("系统错误请联系管理员");
          // }
          addCategory({
            categoryParCode: categoryParCode,
            categoryName: categoryName,
            sort: sort,
          })
            .then((result) => {
              //新增结束通知父组件重新获取数据
              this.$emit("myevent");
              this.$refs.CateforySelectReduce.getNewOptions()
              if (result.data.success) {
                message.success("新增品类成功");
                this.confirmLoading = false;
                this.visible = false;
              } else {
                this.confirmLoading = false;
              }
            })
            .catch(() => {
              this.confirmLoading = false;
            });
        });
      });
    },
  },
  mounted() {},
});
</script>
<style lang="scss" scoped>
.modal {
  border-radius: 20px;
}
input {
  width: 70%;
}
.tips {
  color: #ccc;
  text-align: center;
}
.textarea {
  border-radius: 5px;
  border-color: #e1e1e1;
}
.positionBox {
  position: relative;
  > span {
    position: absolute;
    bottom: -27px;
    left: 80px;
  }
}
</style>
<style lang="scss" >
.CategroyModal .ant-select-selection-placeholder {
  height: 40px !important;
  line-height: 40px !important;
}
.CategroyModal .ant-select-selector {
  height: 40px !important;
  line-height: 40px !important;
}
.CategroyModal .ant-select-selection-item {
  height: 40px !important;
  line-height: 40px !important;
}
.CategroyModal input {
  width: 240px;
  height: 40px;
  line-height: 40px;
  border-radius: 2px;
  border: 1px solid #d9d9d9;
}
</style>