
import { defineComponent } from "vue";
import { categoryList } from "@/api/terminalList.js";
import { Cascader } from "ant-design-vue";
export default defineComponent({
  components: {
    Cascader,
  },
  data() {
    return {
      options: [],
    };
  },
  props: {
    formState: {
      type: Object,
      defaul: {},
    },
  },
  methods: {
    convertKey(arr, level) {
      level++;
      arr.forEach((item) => {
        item.level = level;
        if (item["childBrandList"].length != 0) {
          item.level = level;
          this.convertKey(item.childBrandList, level);
        }
      });
      return arr;
    },
    loadData(selectedOptions) {
       
      let targetOption = selectedOptions[selectedOptions.length - 1];
      targetOption.loading = true; // load options lazily
      return categoryList(targetOption.categoryCode).then((res: any) => {
        targetOption.loading = false;
        let newOptions = res.data.data.map((item) => {
          if (selectedOptions.length <= 2) {
            return {
              ...item,
              value: item.categoryCode,
              label: item.categoryName,
              isLeaf: !item.isHave,
            };
          }
          if (selectedOptions.length > 2) {
            return {
              ...item,
              value: item.categoryCode,
              label: item.categoryName,
              isLeaf: true,
            };
          }
        });
        targetOption.children = newOptions;
        this.options = [...this.options];
      });
    },
    //获取第一列数据
    getNewOptions() {
      let newOptions;
      return categoryList("").then((res: any) => {
        newOptions = res.data.data.map((item) => {
          let checkIsLeaf = item.isHave;
          return {
            ...item,
            value: item.categoryCode,
            label: item.categoryName,
            isLeaf: !checkIsLeaf,
          };
        });
        this.options = newOptions;
      });
    },
  },
  mounted() {
    this.getNewOptions();
  },
});
